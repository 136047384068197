import React from "react"
import { graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image";

// import app components
import { Spacer, Edges } from "components"

import Hero from "components/hero"
import Textarea from "components/textarea"

export default function StoryTemplate(props) {
  const {
    data: {
      page: {
        templateStory: {
          storyFields: {
            storyHero: { image, headline },
            storyContent: { main, sidebar },
            awardsHeadline,
            awardsText,
            storyAwards
          }
        }
      }
    }
  } = props

  return <>
    <Hero border={true} image={image} headline={headline} />

    <ContentContainer>
      <Edges size="cont">
        <Spacer pt={80} />
        <Grid container spacing={1}>
          <Grid item md={2} />
          <Grid item md={5}>
            {main && (
              <Textarea
                content={main}
              />
            )}
          </Grid>
          <Grid item md={1} />
          <Grid item md={4}>
            {sidebar && (
              <Textarea
                content={sidebar}
              />
            )}
          </Grid>
        </Grid>
        <Spacer pb={80} />
      </Edges>
    </ContentContainer>

    <AwardsContainer>
      <Edges size="cont">
        <Spacer pt={60} />
        <Grid container>
          <Grid item md={2} />
          <Grid item md={8}>
            {awardsHeadline && (
              <Typography
                variant="h2"
                children={Parser(awardsHeadline)}
                color="inherit"
              />
            )}
            {awardsText && (
              <Typography
                variant="body1"
                children={Parser(awardsText)}
                color="inherit"
              />
            )}
            <Spacer mb={80} />
            <Grid container spacing={1}>
              {storyAwards && storyAwards.map((o, i) => {
                return (
                  <AwardCont
                    container
                    item
                    key={i}
                    sm={6}
                    lg={3}
                  >
                    {o.awardImage && (
                      <GatsbyImage image={o?.awardImage?.localFile?.childImageSharp?.gatsbyImageData} />
                    )}
                    {o.awardTitle && (
                      <Spacer pl={15} pr={15}>
                        <AwardTitle>{o.awardTitle}</AwardTitle>
                      </Spacer>
                    )}
                  </AwardCont>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Spacer pb={60} />
      </Edges>
    </AwardsContainer>
  </>;
}

const ContentContainer = styled.div`
  background: #000;
  color: white;
`

const AwardsContainer = styled.div``

const AwardCont = styled(Grid)`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  margin-bottom: 50px;
  text-align: center;

  @media (min-width: 768px) {
    border-right: 1px solid;

    &:nth-of-type(2n) {
      border-right: 0px;
    }
  }

  @media (min-width: 1280px) {
    border-right: 1px solid;

    &:nth-of-type(2n) {
      border-right: 1px solid;
    }

    &:nth-of-type(4n) {
      border-right: 0px;
    }
  }
`

const AwardTitle = styled.p`
  letter-spacing: 0.1em;
`

export const CollectionQuery = graphql`query StoryTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateStory {
      storyFields {
        storyHero {
          text
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        storyContent {
          sidebar
          main
        }
        awardsHeadline
        awardsText
        storyAwards {
          awardTitle
          awardImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
}
`
